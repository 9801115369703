const columnsReportsList = [
  'Impressions',
  'Views',
  'Goals',
  'Clicks',
  'Engagements',
  'VTR',
  'CTR',
  'ER',
  'Inversion budget Currency',
  'Inversion budget USD',
  'Spent (USD)',
  'Currency',
  'Spent Currency Deal',
  'ID HS Deal',
  'Campaign ID',
  'LI ID',
  'Start Date',
  'End Date'
]

export default columnsReportsList;