import { useState } from "react";
import { DateRange } from 'react-date-range';
import { Modal } from "react-bootstrap";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from "../../General/Button";

const ModalCustomDate = (props) => {
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleApplyDates = () => {
    props.setSelectedDateRange(dateRanges);
    console.log(dateRanges);
    props.handleClose();
  }

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle modalCustomDate" id="contained-modal-title-vcenter">
          Custom date
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="divDateRange">
          <DateRange
            ranges={dateRanges}
            onChange={item => {
              console.log(item.selection);
              setDateRanges([item.selection])}}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            // rangeColors={["#ff0000"]}
            // showDateDisplay={false}
          />
        </div>
        <div className="divDateRangeButton">
          <h3 onClick={props.handleClose} className="h3Cancel">Cancel</h3>
          <Button title="Apply" className="largeSizeButton" onClick={handleApplyDates} />
        </div>
      </Modal.Body>
    </Modal>
    
  )
}

export default ModalCustomDate;
