import { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SearcherInput from "../General/SearcherInput";
import { getAdvertisers, getCampaigns, getCountries, getMarkets, getInventories } from '../../services/reportsService';
import billableOptions from "./constants/billingOptionsList";
import Button from "../General/Button";

const ModalFiltersReports = (props) => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);


  useEffect(() => {
    data !== null &&
    setFilteredData(
        data.filter(item =>
        (item.name ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, data]);

  const handleSearchFilter = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchData = async (dataFunction) => {
    try {
      const result = await dataFunction;
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
  if (props.show === true) {
    switch (props.title) {
      case "Advertiser":
        fetchData(getAdvertisers());
        break;
      case "Campaign":
        fetchData(getCampaigns());
        break;
      case "Billing Status":
        setData(billableOptions);
        break;
      case "Country":
        fetchData(getCountries());
        break;
      case "Market":
        fetchData(getMarkets());
        break;
        case "Inventory Type":
        fetchData(getInventories());
        break;
      
      default:
        break;
    }
  }
  }, [props.show]);

  const handleCheckboxChange = (item) => {
    if (props.selectedItems.some(selectedItem => selectedItem.id === item.id)) {
      props.setSelectedItems(props.selectedItems.filter(selectedItem => selectedItem.id !== item.id));
    } else {
      props.setSelectedItems([...props.selectedItems, item]);
    }
  };

  const handleApply = () => {
    props.setApplyButton(true);
    props.handleClose();
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setSearchTerm("");
        props.handleClose();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
        <div className="divModalFilterReportsTitle">
          <div className="divModalFilterReportsTitleSearcher">
            <SearcherInput
              iconSize={18}
              onChange={handleSearchFilter}
              placeholder="Search..."
              divStyle="searchDivGrey"
              inputStyle="searchInputGrey"
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="rowModalFilter">
          <Col xs={6} className="colModalFilter">
            {
              filteredData &&
                filteredData.map((item) => (
                  <div key={item.id} className='divListAttribute'>
                    <input
                      key={item.id}
                      type="checkbox"
                      value={item.id}
                      defaultChecked={props.selectedItems.some(selectedItem => selectedItem.id === item.id)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    {item.name}
                  </div>
                ))
            }
          </Col>
          <Col xs={6} className="colModalFilter">
            <h3>Selected</h3>
            {
              props.selectedItems &&
                props.selectedItems.map((item) => (
                  <div className='divListAttribute'>
                    {item.name}
                  </div>
                ))
            }
          </Col>
        </Row>
        <div className="divDateRangeButton">
          <h3 onClick={props.handleClose} className="h3Cancel">Cancel</h3>
          <Button title="Apply" className="largeSizeButton" onClick={handleApply} />
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default ModalFiltersReports; 