const baseURL = process.env.REACT_APP_DOT_NET_BASE_URL;

export const getAdvertisers = async () => {
  try {
    const response = await fetch(`${baseURL}report/get_advertiser`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCampaigns = async (advertiserId) => {
  try {

    const raw = JSON.stringify({
      "advertiserId": []
    });

    const response = await fetch(`${baseURL}report/get_reportcampaign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data?.Campaign;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCountries = async () => {
  try {
    const response = await fetch(`${baseURL}countries/getCountries`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();

    const updatedData = data.data.map(item => ({
      ...item,
      name: item.value,
      value: undefined
    }));
  
    return updatedData
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getMarkets = async () => {
  try {
    const response = await fetch(`${baseURL}campaignManager/pipeline-filter`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();

    const updatedData = data.data.map(item => ({
      name: item.label,
      id: item.pipelineId
    }));
  
    return updatedData
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getInventories = async () => {
  try {
    const response = await fetch(`${baseURL}campaignManager/inventories`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();

    const updatedData = data.data.map(item => ({
      ...item,
      name: item.description,
      description: undefined
    }));
  
    return updatedData
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getPerformanceReport = async (advertisers, campaigns, period) => {
  console.log('period', period);
  console.log('advertisers', advertisers);
  try {
    var raw = JSON.stringify({
      advertiser: ["United Universal Pictures (UIP)","UIP Brasil"],
      campaign: ["19933114446-United Universal Pictures (UIP)-Meu Malvado Favorito 4-Brasil-Jun"],
      format: null,
      startDate: null,
      endDate: null,
      // dimension: dimension,
      period: period,
      impressions: true,
      views: true,
      clicks: true,
      engagements: true,
      ctr: true,
      egRate: true,
      spent_currency_deal: true,
      // is_billable: billable_data ? billable_data : [],
      // FieldName: field,
      // Order: asc ? "asc" : "desc"
    });

    const response = await fetch(`${baseURL}report/getPerformanceReport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


