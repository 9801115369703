const rowReportsList = [
  'Advertisers',
  'Campaign',
  'Line Item',
  'Creative',
  'Format',
  'Date',
  'Offer Type',
  'Inventory Type',
  // 'Country',
  // 'Devices',
  // 'Quartiles',
  'Placements'
]

export default rowReportsList;