import { useState, useEffect } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdOutlineCancel } from "react-icons/md";
import rowReportsList from "./constants/rowsReportsList";
import columnsReportsList from "./constants/columnsReportsList";
import filterReportsList from "./constants/filterReportsList";
import SearcherInput from "../General/SearcherInput";
import ModalFiltersReports from "./modalFiltersReports";
import ModalCustomDate from "./constants/modalCustomDate";
import saveIcon from "../../assets/images/saveIcon.svg";
import resetIcon from "../../assets/images/resetIcon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import { getPerformanceReport } from '../../services/reportsService';

const PerformanceReport = () => {
  const [rowTab, setRowTab] = useState(true);
	const [columnTab, setColumnTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState(['Date']);
  const [selectedColumns, setSelectedColumns] = useState(['Impressions']);
  // const [selectedRows, setSelectedRows] = useState(['Campaign', 'Creative', 'Date', 'Format', 'Offer Type', 'Inventory Type', 'Placements']);
  /*const [selectedColumns, setSelectedColumns] = useState([
    'Impressions',
    'Views',
    'Goals',
    'Clicks',
    'Engagements',
    'VTR',
    'CTR',
    'ER',
    'Inversion budget Currency',
    'Inversion budget USD',
    'Spent (USD)',
    'Currency',
    'Spent Currency Deal',
    'ID HS Deal',
    'Campaign ID',
    'LI ID',
    'Start Date',
    'End Date'
  ]);*/
  const [rowReports, setRowReports] = useState(rowReportsList);
  const [columnsReports, setColumnsReports] = useState(columnsReportsList);
  const [showFilterList, setShowFilterList] = useState(false);
  const [showModalFiltersReports, setShowModalFiltersReports] = useState(false);
  const [showModalCustomDate, setShowModalCustomDate] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedInventoryType, setSelectedInventoryType] = useState([]);
  const [openColRows, setOpenColRows] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [applyButton, setApplyButton] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('entra en el useeffect');
    console.log('selectedDateRange', selectedDateRange);
    console.log('applyButton en el useeffect', applyButton);
    if (selectedDateRange !== null &&
      (selectedAdvertisers.length > 0 || selectedCampaigns.length > 0 || selectedBillingStatus.length > 0 || selectedCountries.length > 0 || selectedMarket.length > 0 || selectedInventoryType.length > 0)
      && applyButton) {
        console.log('entra en el if');
        console.log('selectedDateRange',selectedDateRange)
        const period = selectedDateRange ? selectedDateRange : null;
        console.log('period', period);
        const startDate = null;
        const endDate = null;
        const advertisers = selectedAdvertisers.map((advertiser) => advertiser.name);
        const campaigns = selectedCampaigns.map((advertiser) => advertiser.name);
        const billingStatus = selectedBillingStatus.map((advertiser) => advertiser.name);
        const countries = selectedCountries.map((advertiser) => advertiser.name);
        const markets = selectedMarket.map((advertiser) => advertiser.name);
        const inventoryType = selectedInventoryType.map((advertiser) => advertiser.name);
        getPerformanceReport(advertisers, campaigns, period).then((res) => setData(res.data));
        setApplyButton(false);
    }
  }, [applyButton]);

  useEffect(() => {
    getPerformanceReport().then((res) => setData(res.data));
  }, []);

  const getSelectedItems = (filter) => {
    switch (filter) {
      case 'Advertiser':
        return selectedAdvertisers;
      case 'Campaign':
        return selectedCampaigns;
      case 'Billing Status':
        return selectedBillingStatus;
      case 'Country':
        return selectedCountries;
      case 'Market':
        return selectedMarket;
      case 'Inventory Type':
        return selectedInventoryType;
      default:
        return [];
    }
  };

  const getSetSelectedItems = (filter) => {
    switch (filter) {
      case 'Advertiser':
        return setSelectedAdvertisers;
      case 'Campaign':
        return setSelectedCampaigns;
      case 'Billing Status':
        return setSelectedBillingStatus;
      case 'Country':
        return setSelectedCountries;
      case 'Market':
        return setSelectedMarket;
      case 'Inventory Type':
        return setSelectedInventoryType;
      default:
        return () => {};
    }
  };


  const handleRowTab = () => {
		setRowTab(true);
		setColumnTab(false);
	}

  const handleColumnTab = () => {
		setRowTab(false);
		setColumnTab(true);
	}

  const handleSearchAttribute = (e) => {
    setSearchTerm(e.target.value);
  }

  const handleSearchFilter = (e) => {
    setSearchFilter(e.target.value);
  }

  const filteredReports = (reports, searchType) => {
    return reports.filter((item) =>
      item.toLowerCase().includes(searchType.toLowerCase())
    ).sort((a, b) => a.localeCompare(b));
  };

  const handleRowClick = (item) => {
    setSelectedRows([...selectedRows, item]);
    setRowReports(rowReports.filter(report => report !== item));
  };

  const handleColumnClick = (item) => {
    setSelectedColumns([...selectedColumns, item]);
    setColumnsReports(columnsReports.filter(report => report !== item));
  };

  const handleCloseFiltersReportsModal = () => {
    setShowModalFiltersReports(false);
  }

  const handleCloseCustomDateModal = () => {
    setShowModalCustomDate(false);
  }

  return (
    <div className="divContentPerformanceReport">
      <div className="divBoxPerformanceReport">
        <section className="divSectionPerformanceReport">
          <Row>
            <Col xs={5}>
              <input className="reportInputName" placeholder="Name of report" type="text" name="name" />
            </Col>
            <Col xs={5}>
              <select
                className="reportsInputDates"
                onChange={(e) => {
                  e.target.value === "customDate" ?
                    setShowModalCustomDate(true)
                  : setSelectedDateRange(e.target.value);
                }}
              >
                <option value="someOption">Date range</option>
                <option value="yesterday">Yesterday</option>
                <option value="today">Today</option>
                <option value="thisMonth">This month</option>
                <option value="lastSevenDays">Last seven days</option>
                <option value="last_month">Last month</option>
                <option value="customDate">Custom date</option>
              </select>
            </Col>
            {/* <Col xs={2} className="divButtonsPerformanceReports">
              <Button title="Save" className="mediumSizeButton" />
            </Col>
            <Col xs={2} className="divButtonsPerformanceReports">
              <Button title="Reset" className="mediumSizeButton" />
            </Col> */}
            <Col xs={2} className="coldivIconsReports">
              <div className="divIconsReports">
                <img src={saveIcon} className="reportIcons" />
                <img src={resetIcon} className="reportIcons" />
                <img src={downloadIcon} className="reportIcons" />
              </div>
            </Col>
          </Row>
        </section>
        <section className="divSectionPerformanceReport">
          <div className="divFiltersPerformanceReport">
            <p className="pFilters">Filters</p>
            {selectedAdvertisers.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Advertiser")
                  setShowModalFiltersReports(true)
                }}
              >
                Advertiser
              </button>
            )}
            {selectedCampaigns.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Campaign")
                  setShowModalFiltersReports(true)
                }}
              >
                Campaign
              </button>
            )}
            {selectedBillingStatus.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Billing Status")
                  setShowModalFiltersReports(true)
                }}
              >
                Billing Status
              </button>
            )}
            {selectedCountries.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Country")
                  setShowModalFiltersReports(true)
                }}
              >
                Country
              </button>
            )}
            {selectedMarket.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Market")
                  setShowModalFiltersReports(true)
                }}
              >
                Market
              </button>
            )}
            {selectedInventoryType.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Inventory Type")
                  setShowModalFiltersReports(true)
                }}
              >
                Inventory Type
              </button>
            )}
            <div className="divFilterList">
              <button
                className="btn btn-primary regularButton buttonFilters buttonAdd"
                onClick={() => setShowFilterList(!showFilterList)}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.25 19v-6.25H5v-1.5h6.25V5h1.5v6.25H19v1.5h-6.25V19h-1.5z"
                    fill="#fff"
                  />
                </svg>
                Add
              </button>
              {showFilterList && (
                <div
                  className="divFiltersListPerformanceReport"
                  tabIndex={1}
                  onBlur={() => setShowFilterList(false)}
                >
                  <div className="divSearcherFilters">
                    <SearcherInput
                      iconSize={18}
                      onChange={handleSearchFilter}
                      placeholder="Search ..."
                      divStyle="searchDivGrey"
                      inputStyle="searchInputGrey"
                    />
                  </div>
                  <div className="divSearcherFilters cursorPointer">
                    {
                      filteredReports(filterReportsList, searchFilter).map((item, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            setSelectedFilter(item)
                            setShowModalFiltersReports(true)
                          }}
                        >
                          {item}
                        </p>
                      ))
                    
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="divSectionPerformanceReport">
          <div className="divRowsColumnsPerformanceReport">
            <Collapse dimension={"width"} in={openColRows}>
              <div className="divCollapseRowColPerformanceReport">
                <div className="divItemsPerformanceReport">
                  <Row className="rowItemsPerformanceReport">
                    <Col xs={7}>
                      <h5>Select or search the items with which you want to build the report</h5>
                      <div className="tabsColumnRow">
                        <Row>
                          <Col xs={6} onClick={handleRowTab} className={`colColumnRows ${rowTab ? 'colReportBorderBottom' : ''}`}>
                            <h5 className={`${rowTab ? 'colReportFont' : ''}`}>Row</h5>
                          </Col>
                          <Col xs={6} onClick={handleColumnTab} className={`colColumnRows ${columnTab ? 'colReportBorderBottom' : ''}`}>
                            <h5 className={`${columnTab ? 'colReportFont' : ''}`}>Column</h5>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <SearcherInput
                          iconSize={18}
                          onChange={handleSearchAttribute}
                          placeholder="Search..."
                        />
                        <div className="listColRowReports">
                          {
                            rowTab ?
                              filteredReports(rowReports, searchTerm).map((item, index) => (
                                <p
                                  key={index}
                                  className="cursorPointer"
                                  onClick={() => handleRowClick(item)}
                                >
                                  {item}
                                </p>
                              ))
                              : filteredReports(columnsReports, searchTerm).map((item, index) => (
                                <p
                                  key={index}
                                  className="cursorPointer"
                                  onClick={() => handleColumnClick(item)}
                                >
                                  {item}
                                </p>
                              ))
                          }
                        </div>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div className="divContentSelectedAttributesPerformanceReport">
                        <div className="divSelectedAttributesPerformanceReport">
                          <h5>Rows</h5>
                          {
                            selectedRows.map((item, index) => (
                              <div key={index} className="divIndividualSelectedAttributesPerformanceReport">
                                <p key={index} className="divIndividualParagraphSelectedAttributesPerformanceReport">{item}</p>
                                <MdOutlineCancel
                                  className="cursorPointer"
                                  size={16}
                                  onClick={() => {
                                    setSelectedRows(selectedRows.filter(selectedItem => selectedItem !== item));
                                    setRowReports([...rowReports, item]);
                                  }}
                                />
                              </div>
                            ))
                          }
                        </div>
                        <div className="divSelectedAttributesPerformanceReport">
                          <h5>Columns</h5>
                          {
                            selectedColumns.map((item, index) => (
                              <div key={index} className="divIndividualSelectedAttributesPerformanceReport">
                                <p key={index} className="divIndividualParagraphSelectedAttributesPerformanceReport">{item}</p>
                                <MdOutlineCancel
                                  className="cursorPointer"
                                  size={16}
                                  onClick={() => {
                                    setSelectedColumns(selectedColumns.filter(selectedItem => selectedItem !== item));
                                    setColumnsReports([...columnsReports, item]);
                                  }}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
            <button className="collapsableButton" onClick={() => setOpenColRows(!openColRows)}>
              {openColRows ?
                <MdOutlineKeyboardDoubleArrowLeft /> :
                <MdOutlineKeyboardDoubleArrowRight />
              }
            </button>
            <div className={`divContentResultsPerformanceReport ${openColRows ? 'contentHalfResultsPerformanceReport' : 'contentFullResultsPerformanceReport'}`}>
              <div className="divResultsRowColPerformanceReport">
                <div className="divItemsPerformanceReport">
                  {data &&
                    <table>
                      <tr className="trPerformanceReport">
                        {selectedRows.find(item => item === "Advertiser") && <th className="thPerformanceReport">Advertiser</th>}
                        {selectedRows.find(item => item === "Campaign") && <th className="thPerformanceReport">Campaign</th>}
                        {selectedRows.find(item => item === "Creative") && <th className="thPerformanceReport">Creative</th>}
                        {selectedRows.find(item => item === "Date") && <th className="thPerformanceReport">Date</th>}
                        {selectedRows.find(item => item === "Format") && <th className="thPerformanceReport">Format</th>}
                        {selectedRows.find(item => item === "Inventory Type") && <th className="thPerformanceReport">Inventory Type</th>}
                        {selectedRows.find(item => item === "Offer Type") && <th className="thPerformanceReport">Offer Type</th>}
                        {/*{selectedRows.find(item => item === "Placements") && <th className="thPerformanceReport">Placements</th>*/}
                        {selectedColumns.find(item => item === "Impressions") && <th className="thPerformanceReport">Impressions</th>}
                        {selectedColumns.find(item => item === "Views") && <th className="thPerformanceReport">Views</th>}
                        {selectedColumns.find(item => item === "Goals") && <th className="thPerformanceReport">Goals</th>}
                        {selectedColumns.find(item => item === "Clicks") && <th className="thPerformanceReport">Clicks</th>}
                        {selectedColumns.find(item => item === "Engagements") && <th className="thPerformanceReport">Eng</th>}
                        {selectedColumns.find(item => item === "VTR") && <th className="thPerformanceReport">VTR</th>}
                        {selectedColumns.find(item => item === "CTR") && <th className="thPerformanceReport">CTR</th>}
                        {selectedColumns.find(item => item === "ER") && <th className="thPerformanceReport">ER</th>}
                        {selectedColumns.find(item => item === "Inversion budget Currency") && <th className="thPerformanceReport">Inversion budget Currency</th>}
                        {selectedColumns.find(item => item === "Inversion budget USD") && <th className="thPerformanceReport">Inversion budget USD</th>}
                        {selectedColumns.find(item => item === "Spent (USD)") && <th className="thPerformanceReport">Spent (USD)</th>}
                        {selectedColumns.find(item => item === "Currency") && <th className="thPerformanceReport">Currency</th>}
                        {selectedColumns.find(item => item === "Spent Currency Deal") && <th className="thPerformanceReport">Spent Currency Deal</th>}
                        {selectedColumns.find(item => item === "ID HS Deal") && <th className="thPerformanceReport">ID HS Deal</th>}
                        {selectedColumns.find(item => item === "Campaign ID") && <th className="thPerformanceReport">Campaign ID</th>}
                        {selectedColumns.find(item => item === "LI ID") && <th className="thPerformanceReport">LI ID</th>}
                        {selectedColumns.find(item => item === "Start Date") && <th className="thPerformanceReport">Start Date</th>}
                        {selectedColumns.find(item => item === "End Date") && <th className="thPerformanceReport">End Date</th>}
                      </tr>
                      {data.map((item, index) => (
                        <tr key={index} className="trPerformanceReport">
                          {selectedRows.find(item => item === "Advertiser") && <td className="tdPerformanceReport">{item.advertiser}</td>}
                          {selectedRows.find(item => item === "Campaign") && <td className="tdPerformanceReport">{item.campaign}</td>}
                          {selectedRows.find(item => item === "Creative") && <td className="tdPerformanceReport">{item.creative}</td>}
                          {selectedRows.find(item => item === "Date") && <td className="tdPerformanceReport">{item.date && item.date.split('T')[0]}</td>}
                          {selectedRows.find(item => item === "Format") && <td className="tdPerformanceReport">{item.format}</td>}
                          {selectedRows.find(item => item === "Inventory Type") && <td className="tdPerformanceReport">{item.inventoryType}</td>}
                          {selectedRows.find(item => item === "Offer Type") && <td className="tdPerformanceReport">{item.offerType}</td>}
                          {/*selectedRows.find(item => item === "Placements") && <td className="tdPerformanceReport">{item.placements}</td>*/}
                          {selectedColumns.find(item => item === "Impressions") && <td className="tdPerformanceReport">{item.impressions}</td>}
                          {selectedColumns.find(item => item === "Views") && <td className="tdPerformanceReport">{item.views}</td>}
                          {selectedColumns.find(item => item === "Goals") && <td className="tdPerformanceReport">{item.goal}</td>}
                          {selectedColumns.find(item => item === "Clicks") && <td className="tdPerformanceReport">{item.clicks}</td>}
                          {selectedColumns.find(item => item === "Engagements") && <td className="tdPerformanceReport">{item.engagements ? item.engagements.toFixed(2) : '-'}</td>}
                          {selectedColumns.find(item => item === "VTR") && <td className="tdPerformanceReport">{item.views > 0 ? (item.vtr)?.toFixed(2) : "-"}%</td>}
                          {selectedColumns.find(item => item === "CTR") && <td className="tdPerformanceReport">{item.ctr ? item?.ctr.toFixed(2) : (item.impressions > 0 ? ((item.clicks / item.impressions) * 100).toFixed(2) : '-')}%</td>}
                          {selectedColumns.find(item => item === "ER") && <td className="tdPerformanceReport">{item.er?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0.00}%</td>}
                          {selectedColumns.find(item => item === "Inversion budget Currency") && <td className="tdPerformanceReport">{item.inversionBudget}</td>}
                          {selectedColumns.find(item => item === "Inversion budget USD") && <td className="tdPerformanceReport">{item.inversionBudgetUsd}</td>}
                          {selectedColumns.find(item => item === "Spent (USD)") && <td className="tdPerformanceReport">{item.spentUsd}</td>}
                          {selectedColumns.find(item => item === "Currency") && <td className="tdPerformanceReport">{item.currencyCode}</td>}
                          {selectedColumns.find(item => item === "Spent Currency Deal") && <td className="tdPerformanceReport">{item.spent}</td>}
                          {selectedColumns.find(item => item === "ID HS Deal") && <td className="tdPerformanceReport">{item.dealID}</td>}
                          {selectedColumns.find(item => item === "Campaign ID") && <td className="tdPerformanceReport">{item.campaignID}</td>}
                          {selectedColumns.find(item => item === "LI ID") && <td className="tdPerformanceReport">{item.kiteLineItemId}</td>}
                          {selectedColumns.find(item => item === "Start Date") && <td className="tdPerformanceReport">{item.startDate.split('T')[0]}</td>}
                          {selectedColumns.find(item => item === "End Date") && <td className="tdPerformanceReport">{item.endDate.split('T')[0]}</td>}
                        </tr>
                      ))}
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        <ModalFiltersReports
          show={showModalFiltersReports}
          handleClose={handleCloseFiltersReportsModal}
          title={selectedFilter}
          selectedItems={getSelectedItems(selectedFilter)}
          setSelectedItems={getSetSelectedItems(selectedFilter)}
          setApplyButton={setApplyButton}
        />

        <ModalCustomDate
          show={showModalCustomDate}
          handleClose={handleCloseCustomDateModal}
          setSelectedDateRange={setSelectedDateRange}
        />
      </div>
    </div>
  )
};

export default PerformanceReport;